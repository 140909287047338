import React from 'react'
import { graphql } from 'gatsby'


import Text from "../content/texts/Text"

import Layout from '../components/layout'
import SEO from '../components/seo'

export default function LegalPage(props) {
  return (
    <Layout>
        <SEO 
        title={`An independent creative network`} 
        description={`Here's where we post our latest and greatest work. Come see what we've been up to.`} 
        bodyClass={`is-legal`}
        />
        <section className="static_page legal_page container-fluid">
            <header className="row contact_page__header mb-5">
                <h1 className="col-12 xl">Privacy policy</h1>
            </header>
            <div className="row">
              {props.data.legalStuff && (
                <aside className="contact_page__intro_text col-12 col-md-10 col-lg-7">
                  <Text text={props.data.legalStuff} container={false} />
                </aside>
              )}
            </div>
        </section>
    </Layout>
  )
}
export const query = graphql`
  query LegalQuery {
    legalStuff: contentfulTexts(slug: { eq: "legal-stuff" }) {
      ...textFragment
    }
  }
`